<template>
  <div
    class="d-flex justify-center align-top"
    style="height:100%"
  >
    <v-simple-table
      style="width:100%"
      height="100%"
      fixed-header
    >
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              Payload Key
            </th>
            <th class="text-left">
              Value
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in payloadValues"
            :key="item.key"
          >
            <td>{{ item.key }}</td>
            <td>{{ item.value }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { floatPrecision, underscoreToSpace } from '../../../utils/utils';
import WidgetMixin from '../../../mixins/WidgetMixin';


export default {
  name: 'TableWidget',
  mixins: [WidgetMixin],

  computed: {
    payloadValues() {
      return this.data.payload_keys.map((p) => ({
        key: underscoreToSpace(p),
        value: floatPrecision(this.deviceData.latest_payload[p]),
      }));
    },
  },
};
</script>
